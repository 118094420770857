import {
  mdiHome,
  mdiExitToApp,
  mdiAccount,
  mdiAccountMultiple,
  mdiInformation,
  mdiCheck,
  mdiMessageAlert,
  mdiDomain,
  mdiEmail,
  mdiAlert,
  mdiHomeCity,
  mdiWrench,
  mdiAndroidMessages,
  mdiAutorenew,
  mdiHelpCircle,
  mdiFileDocumentOutline,
} from "@mdi/js";

export interface Menu {
  to?: string;
  href?: string;
  icon: string;
  title: string;
}

export const menus: Menu[] = [
  {
    icon: mdiHome,
    to: "/",
    title: "ホーム",
  },
  {
    icon: mdiExitToApp,
    to: "/cancels",
    title: "解約",
  },
  {
    icon: mdiHomeCity,
    to: "/buildings",
    title: "建物",
  },
  {
    icon: mdiAccount,
    to: "/owners",
    title: "オーナー",
  },
  {
    icon: mdiAccountMultiple,
    to: "/members",
    title: "契約者・入居者",
  },
  {
    icon: mdiInformation,
    to: "/notices/owner",
    title: "お知らせ",
  },
  {
    icon: mdiCheck,
    to: "/procedures",
    title: "各種手続き",
  },
  {
    icon: mdiAutorenew,
    to: "/renewals",
    title: "契約更新",
  },
  {
    icon: mdiMessageAlert,
    to: "/surveys",
    title: "アンケート",
  },
  {
    icon: mdiAndroidMessages,
    to: "/messages",
    title: "SMS",
  },
  {
    icon: mdiEmail,
    to: "/maillogs",
    title: "メールログ",
  },
  {
    icon: mdiAlert,
    to: "/bounces",
    title: "エラーメール",
  },
  {
    icon: mdiDomain,
    to: "/companies",
    title: "業者",
  },
  {
    icon: mdiWrench,
    to: "/repairs",
    title: "修理",
  },
  {
    icon: mdiFileDocumentOutline,
    to: "/documents",
    title: "ドキュメント",
  },
  {
    icon: mdiHelpCircle,
    href: "https://chat.google.com/room/AAAA0rK61gw?cls=7",
    title: "フィードバック",
  },
];
