
import Vue from "vue";

import { menus } from "@/options";

export default Vue.extend({
  computed: {
    menus() {
      return menus.filter((menu) => {
        return menu.to !== "/";
      });
    },
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: true,
          to: "/",
        },
      ];
    },
  },
});
